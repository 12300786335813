import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../shared/storage/local-storage.service';
import { Router, NavigationEnd } from "@angular/router";
import { AppRoutes } from '../../shared/routes/appRoutes';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'app-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private ls: LocalStorageService,
              private router: Router) { }
    public APP_ROUTES = AppRoutes;

  ngOnInit(): void {}


  public signOut(): void{
    this.ls.clear();
    this.router.navigate([AppRoutes.login]);
  }

}
