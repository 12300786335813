import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import {CustomFormsModule} from 'ng2-validation';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {DialogModule} from 'primeng/dialog';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ReadMoreComponent } from './components/read-more/read-more.component';
import { OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ToastrModule } from 'ngx-toastr';
import { ValidationErrorsComponent } from './components/validation-errors/validation-errors.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { OrderModule } from 'ngx-order-pipe';

@NgModule({
  declarations: [
    ReadMoreComponent,
    ValidationErrorsComponent
  ],
  providers: [
  ],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    PaginationModule,
    ToastrModule,
    ConfirmationPopoverModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      },
      extend: true
    }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OrderModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    DialogModule,
    FormsModule,
    ReactiveFormsModule,
    AngularEditorModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    CustomFormsModule,
    NgSelectModule,
    NgxUiLoaderModule,
    ReadMoreComponent,
    PaginationModule,
    ToastrModule,
    ValidationErrorsComponent,
    ConfirmationPopoverModule,
    NgxAudioPlayerModule,
    OrderModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {provide: OWL_DATE_TIME_LOCALE, useValue: localStorage.getItem('selectedLang') ? localStorage.getItem('selectedLang') : 'de'}
      ]
    };
  }
}



export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
