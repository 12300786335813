import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss']
})
export class ValidationErrorsComponent implements OnInit {
  @Input() control = null;
  @Input() controlName = '';
  @Input() password = false;
  @Input() password1 = false;
  @Input() set lteValue(lteValue) {
    this.lteError = {max: lteValue};
  }
  lteError = {max: 'max value'};
  constructor() { }

  ngOnInit(): void {
  }

}
