<div *ngIf="control && control.touched && control.errors" class="text-danger">
    <div *ngIf="control.errors.required">{{controlName}} {{'ValidationErrors.isRequired' | translate}}</div>
    <div *ngIf="control.errors.whitespace">{{controlName}} {{ 'ValidationErrors.shouldNotContainWhiteSpace' | translate }} </div>
    <div *ngIf="control.errors.maxlength">{{controlName}} {{ 'ValidationErrors.maxLength' | translate:control.errors.maxlength }} </div>
    <div *ngIf="control.errors.minlength">{{controlName}} {{ 'ValidationErrors.minLength' | translate:control.errors.minlength }}  </div>
    <div *ngIf="control.errors.digits">{{controlName}} {{ 'ValidationErrors.shouldContainDigits' | translate }}.</div>
    <div *ngIf="control.errors.email">{{ 'ValidationErrors.emailInvalid' | translate }}</div>
    <div *ngIf="control.errors.rangeLength">{{controlName}} {{ 'ValidationErrors.phoneNoInvalid' | translate }} </div>
    <div *ngIf="control.errors.number">{{controlName}} {{ 'ValidationErrors.numberInvalid' | translate }} </div>
    <div *ngIf="control.errors.max">{{controlName}} {{ 'ValidationErrors.lessThenEqError' | translate:control.errors.max }} </div>
    <div *ngIf="control.errors.min">{{controlName}} {{ 'ValidationErrors.greaterThenEqError' | translate}} </div>
    <div *ngIf="control.errors.isSame">
        {{controlName}} {{ 'ValidationErrors.shouldBeSameAsPassword' | translate }}
    </div>
    <div *ngIf="control.errors.lte">
            {{controlName}} {{ 'ValidationErrors.lessThenEqError' | translate:lteError }}
    </div>
    <div *ngIf="control.errors.pattern">
        <ng-container *ngIf="password">
            {{'ValidationErrors.password_error1' | translate}}<br/>
            {{'ValidationErrors.password_error2' | translate}}<br/>
            {{'ValidationErrors.password_error3' | translate}}<br/>
            {{'ValidationErrors.password_error4' | translate}}<br/>
        </ng-container>

        <ng-container *ngIf="password1">
            {{'ValidationErrors.password_error6' | translate}}<br/>
            {{'ValidationErrors.password_error2' | translate}}<br/>
            {{'ValidationErrors.password_error3' | translate}}<br/>
            {{'ValidationErrors.password_error4' | translate}}<br/>
        </ng-container>

        <ng-container *ngIf="!password || !password1">
            {{controlName}} {{ 'ValidationErrors.invalidFormat' | translate }} 
        </ng-container>
</div>