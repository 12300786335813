import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth/auth.interceptor';
import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {LayoutModule} from './layout/layout.module';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { I18nModule } from './i18n/i18n.module';
import {SharedModule} from './shared/shared.module';
import { NotFoundComponent } from './components/pre-auth-pages/not-found/not-found.component';
import { OrderModule } from 'ngx-order-pipe';
import { ErrorCatchingInterceptor } from './auth/errorcatching';
import { MatNativeDateModule } from '@angular/material/core';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    NotFoundComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    I18nModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1
    }),
    PaginationModule.forRoot(),
    ConfirmationPopoverModule.forRoot({
      confirmButtonType: 'danger', // set defaults here
    }),
    SharedModule.forRoot(),
    OrderModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorCatchingInterceptor, multi: true },
    DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
