import { Component, OnInit } from '@angular/core';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router , NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Motor-Frenzy';
  constructor(
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document,
    private route: Router,
    private loaderService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.route.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.loaderService.start();
      }

      if (event instanceof NavigationEnd) {
        this.loaderService.stop();
      }
    });
  }

}
