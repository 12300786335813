import { LoginComponent } from './components/pre-auth-pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedInUserGuard } from './auth/loggedInUser.guard';
import { ForgotPasswordComponent } from './components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { GuestGuard } from './auth/guest.guard';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [GuestGuard]
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    pathMatch: 'full',
    canActivate: [GuestGuard]
  },
  {
    path: 'forgot_password',
    component: ChangePasswordComponent,
    pathMatch: 'full',
    canActivate: [GuestGuard]
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    scrollPositionRestoration: 'enabled',
     onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
