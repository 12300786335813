import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public translate: TranslateService) { }
  intercept(
    req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
    const token: string = localStorage.getItem("token");
    if (token) {
      req = req.clone({ headers: req.headers.set("Authorization", token) });
      req = req.clone({ headers: req.headers.set("app-lang", this.translate.currentLang === 'de' ? 'de' : 'en') });
    }
    req = req.clone({ headers: req.headers.set("Accept", "application/json") });
    
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
  
}
