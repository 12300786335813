import { PreAuthPagesService } from './../pre-auth-pages.service';
import { Component, OnInit } from '@angular/core';
import { AppRoutes } from 'src/app/shared/routes/appRoutes';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { User } from 'src/app/models/User';
import { ProfileService } from '../../../shared/services/profile.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public APP_ROUTES = AppRoutes;
    public LoginForm: FormGroup;
    public isSubmitting = false;
    public isDisabled = false;
    public authorized = false;
    public passwordVisibility = false;

  constructor(
            private router: Router,
            private auth: PreAuthPagesService,
            private toastr: ToastrService,
            private route: ActivatedRoute,
            public translateService: TranslateService,
            private loaderService: NgxUiLoaderService,
            private authService: AuthService,
            private profileService: ProfileService,
            private storageService: StorageService) { }

  ngOnInit(): void {
    this.initForm();
  }

  get formControls(): {[key: string]: AbstractControl} {
        return this.LoginForm.controls;
    }

  private initForm(): void {
        this.LoginForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.pattern(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/)]),
            accessKey: new FormControl('', [Validators.required])
        });
  }
  clearFocus(element: HTMLElement): void {
    element.blur();
  }

  public login(btn: HTMLButtonElement): void {
        if (btn) {
            this.clearFocus(btn);
        }
        if (this.LoginForm.invalid) {
            this.isSubmitting = true;
            return;
        }
        this.isSubmitting = false;
        this.loaderService.start();
        this.authService.login(this.LoginForm.value)
        .subscribe((data) => {
          this.loaderService.stop();
          if (data) {
            document.body.style.minHeight = 'auto';
            this.storageService.saveLoggedInUser(data);
            this.getProfile();
          } else {
            this.toastr.error('', 'Something went wrong!. Try again later');
          }
        }, (res) => {
            this.loaderService.stop();
            if (res.status === 400) {
                const message = res.error && res.error.message ? res.error.message : 'Something Went Wrong';
                this.toastr.error('', message);
                return;
            }
            if (res.status === 403) {
              const title = 'Account Blocked';
              const html = 'Your account has been blocked by admin';
              this.toastr.error('Your account has been blocked by admin', 'Account Blocked');
              return;
            }
            this.toastr.error('', 'Something Went Wrong');
        });
        // localStorage.setItem('token', "user.token");
        // this.router.navigate(['/', 'dashboard']);
    }
    getProfile(): void {
      this.loaderService.start();
      this.profileService.profile().subscribe((profileUser) => {
        this.loaderService.stop();
        let profile = profileUser;
        if (!profile) {
          this.toastr.error(this.translateService.instant('messages.SomethingWentWrong'), '', { timeOut: 5000 });
          return;
        }else{
           localStorage.setItem('user', JSON.stringify(profile));
           this.router.navigate(['/', 'dashboard']);
        }
      }, (res) => {
        this.loaderService.stop();
        if (!(res.status === 400 || res.status === 422 || res.status === 401)) {
          this.toastr.error(this.translateService.instant('messages.SomethingWentWrong'), '', { timeOut: 5000 });
          return;
        }
        this.toastr.error
        ('',
        res.error.message ? res.error.message : this.translateService.instant('messages.SomethingWentWrong'),
        { timeOut: 5000 });
      });
    }

}
