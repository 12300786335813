import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../shared/services/storage.service';
import { AppRoutes } from '../shared/routes/appRoutes';
@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {

    constructor(private router: Router, private toastrService: ToastrService, private storage: StorageService,) {
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) {
                        errorMsg = `Error: ${error.error.message}`;

                    } else {
                        if (error?.status === 400) {
                            this.storage.clear();
                            this.router.navigate([AppRoutes.login]);
                            return throwError(error.error);
                        }
                    }

                    return throwError(error.error);


                })
            )
    }
}