export class AppRoutes {

    // * Pre auth Layout */
    public static readonly login = 'login';
    public static readonly forgotPassword = 'forgot-password';
    public static readonly changePassword = 'reset_password';
    public static readonly setSocialPassword = 'set_password';
    public static readonly register = 'register';
    public static readonly verification = 'verification';
    public static readonly aboutUs = 'aboutUs';
    public static readonly termsAndConditions = 'termsAndConditions';
    public static readonly privacyPolicy = 'privacyPolicy';

    // * post auth Layoyt */
    public static readonly home = 'home';
    public static readonly myProfile = 'my-profile';
    public static readonly dashboard = 'dashboard';
    public static readonly resetPassword = 'reset-password';
    public static readonly accountList = 'account-list';
    public static readonly AccountEdit = 'account-edit';
    public static readonly SitesList = 'sites';
    public static readonly SitesAdd = 'sites/add';
    public static readonly SitesEdit = 'sites/edit';
    public static readonly Customers = 'customers';
    public static readonly AddUser = 'add';
    public static readonly EditUser = 'edit';
    public static readonly Categories = 'categories';
    public static readonly Questions = 'questions';
    public static readonly Subscriptions = 'manage-feeds';
    public static readonly Projects = 'spam-reports';
    public static readonly TermsConditions = 'terms';
    public static readonly PrivacyPolicy = 'privacy-policy';
    public static readonly FAQ = 'faq';
    public static readonly Aboutus = 'aboutus';
}
