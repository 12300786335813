import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';
import { Router, NavigationEnd } from '@angular/router';
import { AppRoutes } from '../../shared/routes/appRoutes';
import { TranslateService } from '@ngx-translate/core';
import { EventsService } from 'src/app/shared/services/events.service';
import { DateTimeAdapter } from 'ng-pick-datetime';

@Component({
  selector: 'app-top-navbar',
  templateUrl: './top-navbar.component.html',
  styleUrls: ['./top-navbar.component.scss']
})
export class TopNavbarComponent implements OnInit {
  isGerman: boolean;
  name: string = null;
  constructor(
    private storage: StorageService,
    private router: Router,
    public translate: TranslateService,
    public eventService: EventsService) { }
  ngOnInit(): void {
    this.eventService.suscribeProfileUpdatedEvent(() => {
      const loggedinUser = this.storage.getLoggedInUser();
      if (loggedinUser) {
        this.name  = loggedinUser.firstName + ' ' + loggedinUser.lastName;
      }
    });
    this.isGerman = this.translate.currentLang === 'de' ? true : false;
    const user = this.storage.getLoggedInUser();
    if (user) {
      this.name  = user.firstName + ' ' +  user.lastName;
    }
  }
  public signOut(): void {
   this.storage.clear();
   this.router.navigate([AppRoutes.login]);
  }
  changelanguage(): void {
    this.isGerman = !this.isGerman;
    if (this.isGerman) {
      this.translate.use('de');
    } else {
      this.translate.use('en');
    }
    localStorage.setItem('selectedLang', this.isGerman ? 'de' : 'en');
    this.eventService.triggerCalenderFormatUpdated(this.isGerman ? 'de' : 'en');
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/')
    .then(() => {
      this.router.navigated = false;
      this.router.navigateByUrl(currentUrl);
    });
  }
}
