import { Injectable } from '@angular/core';
import { EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private profileUpdated: EventEmitter<void> = new EventEmitter<void>();
  private calenderFormatUpdated: EventEmitter<string> = new EventEmitter<string>();

  public suscribeProfileUpdatedEvent(callback: () => void): void {
    this.profileUpdated.subscribe(callback);
  }

  public triggerProfileUpdate(): void {
    this.profileUpdated.emit();
  }

  public suscribeCalenderFormatUpdated(callback: (format: string) => void): void {
    this.calenderFormatUpdated.subscribe(callback);
  }

  public triggerCalenderFormatUpdated(format): void {
    this.calenderFormatUpdated.emit(format);
  }
  constructor() { }

}
