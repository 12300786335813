import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbResponse } from 'src/app/models/DbResponse';
import { User } from 'src/app/models/User';
import { ApiRoutes } from '../routes/apiRoutes';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  public login(data): Observable<User> {
    return this.http.post(ApiRoutes.login, data).pipe(map((res: any) => {
      if (res) {
        return res.data;
      }
      return null;
    }));
  }


  public forgotPassword(email): Observable<DbResponse<any>> {
    return this.http.post<DbResponse<any>>(ApiRoutes.forgotPassword, email);
  }

}
