import { Injectable } from '@angular/core';
import { User } from 'src/app/models/User';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  saveLoggedInUser(user: User): void {
   // localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', user.token);
  }

  updateUser(user: User): void {
    localStorage.setItem('user', JSON.stringify(user));
  }
  getLoggedInUser(): any  {
    const user  = localStorage.getItem('user');
    if (!user) {
      return null;
    }
    return JSON.parse(user);
  }

  clear(): void {
    localStorage.clear();
  }
}
