<ng-container>
  <!-- Main Sidebar Container -->
  <aside style="height: 100%;" class="main-sidebar sidebar-dark-primary">
    <!-- Brand Logo -->
      <a [routerLink]="['/']" class="brand-link">
         <img src="/assets/img/logo.png" alt="Logo" class="brand-image">
      </a>
      <perfect-scrollbar 
      [scrollIndicators]="true" 
      style="height: calc(100% - 71px);" [config]="config">
      <!-- Sidebar -->
      <div class="sidebar">
        <nav>
              <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                  <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
                  <li class="nav-item">
                      <a href="javascript:;" class="nav-link" [routerLink]="['/' , APP_ROUTES.dashboard]" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}">
                          <i class="far fa-circle nav-icon"></i>
                          {{ "dashboard.title" | translate }}
                      </a>
                  </li>

                  <li class="nav-item">
                    <a href="javascript:;" class="nav-link" [routerLink]="['/' , APP_ROUTES.myProfile]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                        <i class="far fa-address-card nav-icon"></i>
                        {{'menuBar.MyProfile' | translate }}
                    </a>
                </li>

                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Customers]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                        <i class="far fa-user nav-icon"></i>
                        {{'menuBar.ManageUsers' | translate }}
                    </a>
                </li>

                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Categories]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                        <i class="fas fa-project-diagram nav-icon"></i>
                        {{'menuBar.Categories' | translate }}
                    </a>
                </li>

                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Questions]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fas fa-project-diagram nav-icon"></i>
                        {{'menuBar.Questions' | translate }}
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Subscriptions]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                        <i class="fas fa-map nav-icon"></i>
                        {{'menuBar.Subscriptions' | translate }}
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Projects]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fas fa-briefcase nav-icon"></i>
                        {{'menuBar.Projects' | translate }}
                    </a>
                </li>
                <li class="nav-item" >
                    <a 
                    style="display: flex;
                    align-items: center;"
                    href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.TermsConditions]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fa fa-gavel nav-icon"></i>
                        {{'menuBar.Terms' | translate }}
                    </a>
                </li>
                <li class="nav-item" >
                    <a 
                    style="display: flex;
                    align-items: center;
                    word-break: break-all;"
                    href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.PrivacyPolicy]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fas fa-shield-alt nav-icon"></i>
                        {{'menuBar.PrivacyPolicy' | translate }}
                    </a>
                </li>
                <li class="nav-item" >
                    <a
                    style="display: flex;
                    align-items: center;
                    word-break: break-all;"
                    href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.FAQ]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fa fa-question-circle nav-icon"></i>
                        {{'menuBar.FAQ' | translate }}
                    </a>
                </li>
                <li class="nav-item" >
                    <a
                    style="display: flex;
                    align-items: center;
                    word-break: break-all;"
                    href="javascript:;" class="nav-link" 
                    [routerLink]="['/' , APP_ROUTES.Aboutus]"
                    routerLinkActive="active"  [routerLinkActiveOptions]="{exact: false}"
                    >
                    <i class="fas fa-info-circle nav-icon"></i>
                        {{'menuBar.Aboutus' | translate }}
                    </a>
                </li>
                <li class="nav-item">
                    <a href="javascript:;" class="nav-link" (click)="signOut()"> 
                        <i class="fas fa-sign-out-alt nav-icon"></i> {{'menuBar.Logout' | translate }}</a>
                  </li>
          
              </ul>
          </nav>
      </div>
    </perfect-scrollbar>
  </aside>
</ng-container>