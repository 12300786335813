import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
@Input() text = '';
@Input() length = 50;
isNoWrap = true;
fulltext = '';
isOpen = false;
readMoreButtonText = 'read more';
isShowMoreButton = true;
constructor() { }

  ngOnInit(): void {
    if (this.text.length <= this.length) {
      this.isShowMoreButton = false;
      return;
    }
    this.fulltext = this.text;
    this.changeText();
  }

  toggleReadMore(): void {
    this.isOpen = !this.isOpen;
    this.changeText();
  }

  changeText(): void {
   if (!this.isOpen) {
    this.isNoWrap = true;
    this.text = this.text.substr(0,this.length);
    this.readMoreButtonText = 'read more';
    return;
  }
   this.isNoWrap = false;
   this.text = this.fulltext;
   this.readMoreButtonText = 'read less';
  }

}
