import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ModuleWithProviders } from '@angular/core';

@NgModule({
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [TranslateModule]
})
export class I18nModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['de', 'en']);
    let browserLang = localStorage.getItem('selectedLang');
    if (browserLang === null) {
      localStorage.setItem('selectedLang', 'de');
      browserLang = 'de';
    }
    translate.use(browserLang);
  }

  // static forRoot(): ModuleWithProviders<I18nModule> {
  //   return {
  //     ngModule: I18nModule,
  //     providers: []
  //   };
  // }
}

export function translateLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}