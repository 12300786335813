import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DbResponse } from 'src/app/models/DbResponse';
import { User } from 'src/app/models/User';
import { ApiRoutes } from '../routes/apiRoutes';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  profile(): Observable<User> {
    return this.http.get<DbResponse<User>>(ApiRoutes.getProfile, {}).pipe(map((res:any) => {
      return res.message="retrieved successfully" ? res.data : null;
   }));
  }

  update(data): Observable<DbResponse<any>> {
    return this.http.patch<DbResponse<User>>(ApiRoutes.updateProfile, data);
  }

  updatePasword(data): Observable<DbResponse<any>> {
    return this.http.patch<DbResponse<any>>(ApiRoutes.updateProfilePassword,data);
  }
}
