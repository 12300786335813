import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyProfileComponent } from '../components/post-auth-pages/profile/my-profile/my-profile.component';
import { ChangePasswordComponent } from '../components/pre-auth-pages/forgot-password/change-password/change-password.component';
import { LoginComponent } from '../components/pre-auth-pages/login/login.component';
import { ForgotPasswordComponent } from '../components/pre-auth-pages/forgot-password/forgot-password/forgot-password.component';
import { DashboardComponent } from '../components/post-auth-pages/dashboard/dashboard.component';
import { ResetPasswordComponent } from '../components/post-auth-pages/profile/reset-password/reset-password.component';
import { AccountListComponent } from '../components/post-auth-pages/manage-account/account-list/account-list.component';
import { AccountEditComponent } from '../components/post-auth-pages/manage-account/account-edit/account-edit.component';
import { LoggedInUserGuard } from '../auth/loggedInUser.guard';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AccountComponent } from '../components/post-auth-pages/manage-account/account/account.component';
import { NotFoundComponent } from '../components/pre-auth-pages/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [LoggedInUserGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren:  () => import('../components/post-auth-pages/dashboard/dashboard.module').then(m => m.DashboardModule),
        pathMatch: 'full'
      },
      {
        path: 'account-list',
        loadChildren:  () => import('../components/post-auth-pages/manage-account/manage-account.module').then(m => m.ManageAccountModule),
        pathMatch: 'full'
      },
      {
        path: 'my-profile',
        loadChildren: () => import('../components/post-auth-pages/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'sites',
        loadChildren: () => import('../components/post-auth-pages/manage-sites/manage-sites.module').then(m => m.ManageSitesModule),
        pathMatch: 'full'
      },
      {
        path: 'customers',
        loadChildren: () => import('../components/post-auth-pages/customers/customers.module').then(m => m.CustomersModule),

      },
      {
        path: 'categories',
        loadChildren: () => import('../components/post-auth-pages/categories/categories.module').then(m => m.CategoriesModule),
      },
      {
        path: 'questions',
        loadChildren: () => import('../components/post-auth-pages/questions/questions.module').then(m => m.QuestionsModule),
      },
      {
        path: 'manage-feeds',
        loadChildren: () => import('../components/post-auth-pages/subscriptions/subscription.module').then(m => m.SubscriptionModule),
      },
      {
        path: 'spam-reports',
        loadChildren: () => import('../components/post-auth-pages/projects/projects.module').then(m => m.ProjectsModule),
      },
      {
        path: 'manage-account',
        loadChildren: () => import('../components/post-auth-pages/manage-account/manage-account.module').then(m => m.ManageAccountModule),
        pathMatch: 'full'
      },
      {
        path: 'terms',
        loadChildren: () => import('../components/post-auth-pages/terms/terms.module').then(m => m.TermsModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('../components/post-auth-pages/privacy/privacy.module').then(m => m.PrivacyModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('../components/post-auth-pages/faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: 'aboutus',
        loadChildren: () => import('../components/post-auth-pages/about-us/about-us.module').then(m => m.AboutUsModule),
      }
    ]
  },
  {
      path: '**',
      component: NotFoundComponent,
      pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
