<div class="login-page">

	<div class="login-box">
		<div class="login-logo mb-4">
			<a  href="javascript:;"><img src="/assets/img/logo.png" alt="logo"/></a>
		</div>
		<div class="card">
			<div class="card-body login-card-body">
			<h4 _ngcontent-lqd-c127="" class=" text-center f-700"> Passwort vergessen </h4>
			<hr>
			<p class="login-box-msg">Du hast dein Passwort vergessen? Hier kannst du dir ein neues Passwort zusenden lassen.</p>
		
			<form novalidate [formGroup]="forgotForm">
				<div class="form-group">
				<label>Gib hier deine E-Mail Adresse an</label>
				<div class="input-group">
				<input type="email" class="form-control" placeholder="E-Mail Adresse eingeben"
				formControlName="email" autocomplete="off">
	
				
	
				<div class="input-group-append">
					<div class="input-group-text">
					<span class="fas fa-envelope"></span>
					</div>
				</div>
			
				</div>
	
				<div class="col-md-12">
				<ng-container
					*ngIf="forgotForm.controls['email'].errors && (forgotForm.get('email').dirty || forgotForm.get('email').touched || isSubmitting)">
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.required">E-Mail Adresse ist erforderlich.</small>
					<small class="text-danger error-msg"
						[hidden]="!forgotForm.controls['email'].errors.pattern">E-Mail Adresse ist ungültig.</small>
				</ng-container>
			</div>
	
				</div>
	
		
				<div class="row">
				<div class="col-12">
					<button type="submit" class="btn btn-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block mr-2" (click)="doForgot($event)">Absenden </button>
					<button class="btn btn-outline-primary mt-3 pt-2 pb-2 btn-height fs-6 mw-120 d-inline-block" (click)="gotoLogin()">Abbrechen</button>
				</div>
				<!-- /.col -->
				</div>
			</form>
		
			<!-- <p class="mt-3 mb-1">
				<a class="btn btn-block btn-outline-primary" href="javascript:;" [routerLink]="['../' + APP_ROUTES.login]">Login</a>
			</p> -->
			</div>
			<!-- /.login-card-body -->
		</div>
		</div>
	</div>
	
	
	