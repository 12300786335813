<div class="login-page">
    <div class="login-box">
        <div class="login-logo mb-4">
            <a href="javascript:;"><img src="/assets/img/logo.png" alt="logo" /></a>
        </div>
        <h3 class="text-center">{{ 'notFound.title' | translate }}</h3>
        <div class="d-flex justify-content-center">
            <a href="javascript:;" routerLink="/" class="btn btn-primary">{{'notFound.backToDashboard' | translate}}</a> 
        </div>
    </div>
</div>