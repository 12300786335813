import { environment } from '../../../environments/environment';

export class ApiRoutes {
    // api base url
    static apiV1: string = 'api/v1/';
    public static apiBaseUrl: string = environment.apiBaseUrl;
    public static get login(): string { return this.apiBaseUrl + 'auth/login'; }
    public static get forgotPassword(): string  {return this.apiBaseUrl + 'auth/forgot-password'; }
    public static get addCustomer(): string { return this.apiBaseUrl + 'add_user'; }
    public static get listCustomer(): string { return this.apiBaseUrl + 'user_lists'; }
    public static get deleteCustomer(): string { return this.apiBaseUrl + 'user_delete'; }
    public static get getCustomer(): string { return this.apiBaseUrl + 'user_edit'; }
    public static get updateCustomer(): string { return this.apiBaseUrl + 'user_update'; }
    public static get createCategory(): string { return this.apiBaseUrl + 'categories'; }
    public static get listCategory(): string { return this.apiBaseUrl + 'categories'; }
    public static get listCategoryAll(): string { return this.apiBaseUrl + 'categories/all'; }
    public static get listOrdered(): string { return this.apiBaseUrl + 'category_lists_order'; }
    public static get deleteCategory(): string { return this.apiBaseUrl + 'categories/'; }
    public static get getCategory(): string { return this.apiBaseUrl + 'categories/'; }
    public static get updateCategory(): string { return this.apiBaseUrl + 'categories/'; }
    public static get updateCategoryStatus(): string  {return this.apiBaseUrl + 'categories/'; }
    public static get createQuestion(): string  {return this.apiBaseUrl + 'add_question'; }
    public static get listQuestion(): string  {return this.apiBaseUrl + 'question_lists'; }
    public static get getQuestion(): string  {return this.apiBaseUrl + 'question_edit'; }
    public static get updateQuestion(): string  {return this.apiBaseUrl + 'question_update'; }
    public static get deleteQuestion(): string  {return this.apiBaseUrl + 'question_delete'; }
    public static get listSubscriptions(): string  {return this.apiBaseUrl + 'subscription_lists'; }
    public static get listSubscriptionPlans(): string  {return this.apiBaseUrl + 'plan_list'; }
    public static get createSubscriptions(): string  {return this.apiBaseUrl + 'add_subscription'; }
    public static get updateSubscriptionStatus(): string  {return this.apiBaseUrl + 'subscription_status'; }
    public static get getSubscription(): string {return this.apiBaseUrl + 'subscription_edit'; }
    public static get deleteSubscription(): string { return this.apiBaseUrl + 'subscription_delete'; }
    public static get getProfile(): string {return this.apiBaseUrl + 'admins/me'; }
    public static get updateProfile(): string {return this.apiBaseUrl + 'admins/me'; }
    public static get updateProfilePassword(): string { return this.apiBaseUrl + 'auth/change-password'; }
    public static get listNotifications(): string { return this.apiBaseUrl + 'notification_list'; }
    public static get deleteNotifications(): string { return this.apiBaseUrl + 'notification_delete'; }
    public static get listProjects(): string { return this.apiBaseUrl + 'project_list'; }
    public static get downloadCSV(): string { return this.apiBaseUrl + 'web-users/export'; }
    public static get project(): string { return this.apiBaseUrl + 'project_detail'; }
    public static get deleteProject(): string { return this.apiBaseUrl + 'web-users/'; }
    public static get getTermsConditions(): string { return this.apiBaseUrl + 'pages/terms-conditions'; }
    public static get getPrivacyPolicy(): string { return this.apiBaseUrl + 'pages/privacy-policy'; }
    public static get getAboutUs(): string { return this.apiBaseUrl + 'pages/about-us'; }
    public static get updateStaticContent(): string { return this.apiBaseUrl + 'add_page_content'; }
    public static get listAllCategory(): string { return this.apiBaseUrl + 'categories/all'; }
    public static get listsubcategories(): string { return this.apiBaseUrl + 'sub-categories'; }
    public static get getsubcategorie(): string { return this.apiBaseUrl + 'sub-categories/'; }
    public static get getFAQ(): string { return this.apiBaseUrl + 'faqs'; }
    public static get getFAQbyId(): string { return this.apiBaseUrl + 'faqs/'; }
    public static get getUser(): string { return this.apiBaseUrl + 'web-users'; }
    public static get getUserbyId(): string { return this.apiBaseUrl + 'web-users/'; }
    public static get getNotification(): string { return this.apiBaseUrl + 'dashboard-notifications'; }
    public static get getNotificationById(): string { return this.apiBaseUrl + 'dashboard-notifications/'; }
    public static get getCountries(): string { return this.apiBaseUrl + 'countries/all'; }
    public static get feeds(): string { return this.apiBaseUrl + 'articles'; }
    public static get reportedFeeds(): string { return this.apiBaseUrl + 'reports'; }
    public static get dashboardStatistics(): string { return this.apiBaseUrl + 'web-users/statistics'; }
    public static get feedsStatus(): string { return this.apiBaseUrl + 'articles/'; }
    public static get userWaring(): string { return this.apiBaseUrl + 'articles/warning'; }
    public static get chatWaring(): string { return this.apiBaseUrl + 'chats/warning'; }

}
